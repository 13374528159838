import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import { logger as baseLogger, useNetworkStore } from "@core";
import {
  blockTrend,
  getMonthlyData,
  getYearlyData,
  type MonthlyData,
  type YearlyData,
} from "@core/utils/trend";
import { usePropertyStore } from "@stores/property.store";
import { BaseCard, HeadingBar, InfoCard } from "@shared";
import UtfTable from "@shared/ui/analytics/tables/Table/UtfTable";

import ColCompareCard from "../../components/ColCompareCard";
import FloorTable from "./FloorTable";

const logger = baseLogger.getSubLogger({ name: "PropertyDetailOverview" });

type SensorData = {
  [key in "consumption" | "returnTemp"]: {
    value?: number;
    trend?: number;
    monthly?: MonthlyData;
    yearly?: YearlyData;
  };
};

const blocks = {
  energy: "heat_energy_sum",
  returnTemp: "returntemp_flowweighted_avg",
};

const legends = [
  { name: "text_normal_year", color: "primary.light" },
  { name: "text_measured", color: "secondary.main" },
];

export function PropertyDetailOverview() {
  const { t } = useTranslation();
  const lpYear = useNetworkStore((s) => s.lpYear);
  const resourceId = useNetworkStore((s) => s.selectedSubstationId);
  const fetching = usePropertyStore((s) => s.fetching);
  const error = usePropertyStore((s) => s.error);
  const infoBlocks = usePropertyStore((s) => s.data);
  const months = usePropertyStore((s) => s.requiredMonths);
  const years = usePropertyStore((s) => s.requiredYears);
  // Property data
  const reader = usePropertyStore((s) => s.reader);
  const property = useMemo(() => (!resourceId ? null : reader(resourceId)), [resourceId, reader]);
  const { install_address: installAddress = {}, customer } = property ?? {};

  const data = useMemo(() => {
    const output: SensorData = { consumption: {}, returnTemp: {} };
    if (fetching || error) return output;

    // consumption
    output.consumption = {
      monthly: getMonthlyData(blocks.energy, months, infoBlocks, resourceId),
      yearly: getYearlyData(blocks.energy, years, infoBlocks, resourceId),
    };
    if (output.consumption.yearly) {
      const currentYearIndex = output.consumption.yearly.first.length - 1;
      output.consumption.value = output.consumption.yearly.first[currentYearIndex];
      output.consumption.trend = blockTrend(
        output.consumption.yearly.first[currentYearIndex],
        output.consumption.yearly.first[currentYearIndex - 1]
      );
    }

    // return temperature
    output.returnTemp = {
      monthly: getMonthlyData(blocks.returnTemp, months, infoBlocks, resourceId),
      yearly: getYearlyData(blocks.returnTemp, years, infoBlocks, resourceId),
    };
    if (output.returnTemp.yearly) {
      const currentYearIndex = output.returnTemp.yearly.first.length - 1;
      output.returnTemp.value = output.returnTemp.yearly.first[currentYearIndex];
      output.returnTemp.trend = blockTrend(
        output.returnTemp.yearly.first[currentYearIndex],
        output.returnTemp.yearly.first[currentYearIndex - 1]
      );
    }

    return output;
  }, [error, fetching, infoBlocks, months, resourceId, years]);

  const currentYear = String(lpYear?.year);

  logger.trace("RENDER %j", { fetching, error, resourceId, property, data });

  return (
    <Box width="100%">
      <Grid container columnSpacing={1} my={2}>
        <Grid item xs={8}>
          <BaseCard title="TREND">
            <UtfTable
              initialState={{
                showColumnFilters: false,
                showGlobalFilter: false,
              }}
              positionToolbarAlertBanner="none"
              renderBottomToolbarCustomActions={() => null}
              renderToolbarInternalActions={() => null}
              enableBottomToolbar={false}
              enableTopToolbar={false}
              enableSorting={false}
              data={[
                {
                  id: resourceId,
                  address: customer?.name,
                  city: installAddress?.street,
                  energy: data.consumption.value,
                  rt: data.returnTemp.value,
                  power: 0,
                  flow: 0,
                },
              ]}
              columns={[
                {
                  accessorKey: "address",
                  header: "Address",
                },
                {
                  accessorKey: "city",
                  header: "City",
                },
                {
                  accessorKey: "energy",
                  header: "Energy (MWh)",
                },
                {
                  accessorKey: "power",
                  header: "Power (kW)",
                },
                {
                  accessorKey: "rt",
                  header: "Return temp. (°C)",
                },
                {
                  accessorKey: "flow",
                  header: "Flow (m³)",
                },
              ]}
              muiTablePaperProps={{
                sx: {
                  width: "100%",
                },
              }}
            />
          </BaseCard>
        </Grid>
        <Grid item xs={4}>
          <BaseCard title="APARTMENT TEMPERATURES">
            <FloorTable />
          </BaseCard>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {error ? (
            <div>{error.message}</div>
          ) : (
            <>
              <HeadingBar title="CONSUMPTION" />
              <Grid container spacing={1}>
                {/* Total Consumption */}
                <Grid item xs={3}>
                  <InfoCard
                    title={t("text_normal_year_consumption")}
                    titleAddon={currentYear}
                    value={data.consumption.value}
                    trend={{
                      title: t("text_trend_compared_to_previous_year"),
                      value: data.consumption.trend,
                      suffix: "%",
                    }}
                    loading={fetching}
                    unit="%"
                  />
                </Grid>
                {/* Monthly Consumption */}
                <Grid item xs={6}>
                  <ColCompareCard
                    loading={fetching}
                    title={`12 ${t("text_months")}`}
                    legend={legends}
                    categories={data.consumption.monthly?.months}
                    unit="kWh"
                    series1={{
                      data: data.consumption.monthly?.first,
                      name: "normal",
                      label: "text_normalized_energy",
                    }}
                    series2={{
                      data: data.consumption.monthly?.second,
                      name: "measured",
                      label: "text_measured_energy",
                    }}
                  />
                </Grid>
                {/* Yearly Consumption */}
                <Grid item xs={3}>
                  <ColCompareCard
                    loading={fetching}
                    title={`5 ${t("text_years")}`}
                    legend={legends}
                    categories={data.consumption.yearly?.years}
                    unit="kWh"
                    series1={{
                      data: data.consumption.yearly?.first,
                      name: "normal",
                      label: t("text_normalized_energy"),
                    }}
                    series2={{
                      data: data.consumption.yearly?.second,
                      name: "measured",
                      label: t("text_measured_energy"),
                    }}
                  />
                </Grid>
              </Grid>
              {/* Average Return Temp Section */}
              <HeadingBar
                title="AVERAGE RETURN TEMPERATURE"
                addons={[{ text: t("text_flow_weighted") }]}
              />
              <Grid container spacing={1}>
                {/* Return Temperature */}
                <Grid item xs={3}>
                  <InfoCard
                    title={t("text_normal_year_return_temperature")}
                    titleAddon={currentYear}
                    value={data.returnTemp.value}
                    trend={{
                      title: t("text_trend_compared_to_previous_year"),
                      value: data.returnTemp.trend,
                      suffix: "°C",
                    }}
                    loading={fetching}
                    unit="°C"
                  />
                </Grid>
                {/* Monthly Consumption */}
                <Grid item xs={6}>
                  <ColCompareCard
                    loading={fetching}
                    title={`12 ${t("text_months")}`}
                    legend={legends}
                    categories={data.returnTemp.monthly?.months}
                    unit="°C"
                    series1={{
                      data: data.returnTemp.monthly?.first,
                      name: "normal",
                      label: t("text_normalized_return_temp"),
                    }}
                    series2={{
                      data: data.returnTemp.monthly?.second,
                      name: "measured",
                      label: t("text_measured_return_temp"),
                    }}
                  />
                </Grid>
                {/* Yearly Consumption */}
                <Grid item xs={3}>
                  <ColCompareCard
                    loading={fetching}
                    title={`5 ${t("text_years")}`}
                    legend={legends}
                    categories={data.returnTemp.yearly?.years}
                    unit="°C"
                    series1={{
                      data: data.returnTemp.yearly?.first,
                      name: "normal",
                      label: t("text_normalized_return_temp"),
                    }}
                    series2={{
                      data: data.returnTemp.yearly?.second,
                      name: "measured",
                      label: t("text_measured_return_temp"),
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
