/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { QueryClient, queryOptions } from "@tanstack/react-query";

import networkApi, {
  type GetInfoBlocksParams,
  type GetSubstationMeterDataParams,
  type GetWeatherDataParams,
} from "./network.api";
import userApi from "./user.api";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
    },
  },
});

export const getWeatherData = (params: GetWeatherDataParams) =>
  queryOptions({
    queryKey: ["weather", params],
    queryFn: () => networkApi.getWeatherData(params),
  });

export const getMeterData = (params: GetSubstationMeterDataParams) =>
  queryOptions({
    queryKey: ["meter", params],
    queryFn: () => networkApi.getSubstationMeterData(params),
  });

export const getNetworksQueryOptions = () =>
  queryOptions({
    queryKey: ["networks"],
    queryFn: () => networkApi.getNetworks(),
    retry: 2,
  });

export const getInfoBlockQueryOptions = (params: GetInfoBlocksParams) =>
  queryOptions({
    queryKey: ["infoblock", params],
    queryFn: () => networkApi.getInfoBlocks(params),
  });

export const getNetworkResources = (network: string) =>
  queryOptions({
    queryKey: ["networkResources", network],
    queryFn: () => networkApi.getNetworkResourcesV4(network),
  });

export const getClusterSubstationsOptions = (clusterId: string) =>
  queryOptions({
    queryKey: ["clusterSubstations", clusterId],
    queryFn: () => networkApi.getClusterSubstations(clusterId),
  });

const getUserProfileQueryOptions = (userId: string | undefined) =>
  queryOptions({
    queryKey: ["userProfile", userId],
    queryFn: () => userApi.getUserProfile(),
    enabled: !!userId,
  });

const getGroupsQueryOptions = () =>
  queryOptions({
    queryKey: ["groups"],
    queryFn: () => userApi.getUserGroups(),
  });

const getPoliciesQueryOptions = () =>
  queryOptions({
    queryKey: ["policies"],
    queryFn: () => userApi.getPolicies(),
  });

const getPolicyByIdQueryOptions = (policyId: string | undefined) =>
  queryOptions({
    queryKey: ["policy", policyId],
    queryFn: () => userApi.getPolicyById(policyId!),
    enabled: !!policyId,
  });

const getGroupByIdQueryOptions = (groupId: string | undefined) =>
  queryOptions({
    queryKey: ["group", groupId],
    queryFn: () => userApi.getGroupById(groupId!),
    enabled: !!groupId,
  });

const addNetworkToPolicyByIdQueryOptions = (
  policyId: string | undefined,
  networkId: string | undefined
) =>
  queryOptions({
    queryKey: ["policy", policyId],
    queryFn: () => userApi.addNetworkToPolicyById(policyId!, networkId!),
    enabled: !!policyId && !!networkId,
  });

export {
  getUserProfileQueryOptions,
  getGroupsQueryOptions,
  getPoliciesQueryOptions,
  getPolicyByIdQueryOptions,
  addNetworkToPolicyByIdQueryOptions,
  getGroupByIdQueryOptions,
};
