import { useEffect, useMemo } from "react";
import { IconButton, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { faBuilding, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { Marker } from "@vis.gl/react-google-maps";

import { BAR_HEIGHT } from "@config";
import { useNetworkStore, validCoords } from "@core";
import { FontAwesomeSvgIcon, SectionSelector, SubHeader, UtfMap } from "@shared";
import { markerIconURL } from "@shared/GoogleMap/utils";

import { usePropertyStore } from "../../stores/property.store";
import { PropertyDetailEnergyData } from "./PropertyDetail.energy";
import { PropertyDetailMaintenance } from "./PropertyDetail.maintenance";
import { PropertyDetailOptimization } from "./PropertyDetail.optimization";
import { PropertyDetailOverview } from "./PropertyDetail.overview";

export function PropertyDetailModule() {
  const propertyId = useNetworkStore((s) => s.selectedSubstationId);
  const setPropertyId = useNetworkStore((s) => s.setSelectedSubstationId);
  const fetching = usePropertyStore((s) => s.fetching);
  const fetch = usePropertyStore((s) => s.fetch);
  const refetch = usePropertyStore((s) => s.refetch);
  const reader = usePropertyStore((s) => s.reader);

  const onCloseProperty = () => {
    setPropertyId(undefined);
    refetch();
  };

  useEffect(() => {
    if (!propertyId) return;
    void fetch(propertyId);
  }, [propertyId, fetch]);

  // Property data
  const property = useMemo(() => (!propertyId ? null : reader(propertyId)), [propertyId, reader]);
  const { install_address: installAddress = {}, customer } = property ?? {};
  let title = propertyId;
  if (customer?.name && installAddress?.street)
    title = `${installAddress.street} - ${customer.name}`;

  // Map
  let mapCenter;
  const mapZoom = 15;
  const [lat, lng] = installAddress?.coordinates ?? [];
  if (validCoords(installAddress)) {
    mapCenter = {
      lat,
      lng,
    };
  }

  const MapComponent = fetching ? (
    <Skeleton variant="rectangular" height={155} />
  ) : (
    <UtfMap id="property-detail" defaultZoom={mapZoom} center={mapCenter}>
      <Marker position={mapCenter} icon={{ url: markerIconURL() }} />
    </UtfMap>
  );

  return (
    <div>
      <SubHeader
        endComponent={
          <IconButton color="inherit" onClick={onCloseProperty}>
            <FontAwesomeSvgIcon icon={faChevronLeft} />
          </IconButton>
        }
      >
        <IconButton
          sx={{
            borderRight: 1,
            borderColor: "border.divider.main",
            borderRadius: 0,
            color: "inherit",
            height: BAR_HEIGHT,
            pr: 2,
            my: -2,
            ml: -1,
            mr: 1,
          }}
        >
          <FontAwesomeSvgIcon icon={faBuilding} />
        </IconButton>
        <Box>{title}</Box>
      </SubHeader>

      {/* Google Maps */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          height: 192,
          alignItems: "center",
          border: `1px solid`,
          borderColor: "grey.200",
          borderLeft: 0,
          borderRight: 0,
        }}
      >
        {MapComponent}
      </Box>

      {/* Tabbed sections */}
      <SectionSelector
        sections={[
          { title: "overview", id: "overview", component: PropertyDetailOverview },
          { title: "energy data", id: "energy_data", component: PropertyDetailEnergyData },
          {
            title: "optimization",
            id: "optimization",
            component: PropertyDetailOptimization,
            disabled: true,
          },
          { title: "maintenance", id: "maintenance", component: PropertyDetailMaintenance },
        ]}
      />
    </div>
  );
}
