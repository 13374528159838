import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import { useNetworkStore } from "@core";
import useMaintenanceStore from "@stores/maintenance.store";
import { FilterBar } from "@shared";

import { FaultsAndActions } from "./FaultsAndActions.graph";

export function PropertyDetailMaintenance() {
  const fetchedRange = useNetworkStore((s) => s.fetchedRange);
  const registerChart = useNetworkStore((s) => s.registerChart);

  const seriesData = useMaintenanceStore((s) => s.seriesData);
  const onFilterInputChange = useMaintenanceStore((s) => s.onFilterInputChange);
  const onNavigatorExtremesUpdated = useMaintenanceStore((s) => s.onNavigatorExtremesUpdated);
  const onIntervalChange = useMaintenanceStore((s) => s.onIntervalChange);

  return (
    <Box width="100%">
      <Grid container columnSpacing={1} my={2}>
        <Grid item xs={12}>
          {/* Timeseries graph of Outdoor Temp, Flow, Heat Energy and Temperature */}
          <FaultsAndActions />

          {/* Popover Bottom Filterbar for data filtering */}
          <FilterBar
            data={seriesData}
            range={fetchedRange}
            registerChart={registerChart}
            afterSetExtremes={onNavigatorExtremesUpdated}
            onChange={onFilterInputChange}
            onIntervalChange={onIntervalChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
