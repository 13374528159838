import { AxiosResponse } from "axios";

import { logger as baseLogger } from "@core/logger";
import { Network } from "@core/models";

import useAuthStore from "../stores/auth.store";
import axiosClient from "./apiClient";

const logger = baseLogger.getSubLogger({ name: "network.api" });

export class MdslApi {
  async getAuthToken(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useAuthStore.getState().getToken!();
  }
  get axiosDefaults() {
    return this.getAuthToken().then((token) => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  }

  async registerUser(): Promise<AxiosResponse> {
    return axiosClient
      .post(
        "/users",
        {},
        {
          ...(await this.axiosDefaults),
          params: {
            max_management_level: 4,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        //if 409, user already exists
        if (err.response.status === 409) {
          return;
        }
      });
  }

  async getNetworks(): Promise<Network[]> {
    // /networks
    return axiosClient
      .get("/networks", await this.axiosDefaults)
      .then((res) => res.data.data.collection);
  }
}
