/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTime } from "luxon";
import { create, type StateCreator } from "zustand";

import { blkReader, collectorBlockPath, COLUMN_PERIOD, R12 } from "@config";
import {
  logger as baseLogger,
  getInfoBlockQueryOptions,
  queryClient,
  useNetworkStore,
} from "@core";
import { zustandMiddlware } from "@core/stores/middleware";
import type { ColumnMeta } from "@core/types/common";
import { createInfoBlockGridColumns } from "@core/utils/columns/createInfoBlockGridColumns";
import { generateBlockNames, generateColumnsBySBT, generateSpecs } from "@core/utils/columns/utils";
import type { MonthOption } from "@shared/ui/analytics/tables/InfoBlockGrid/const";

const logger = baseLogger.getSubLogger({ name: "dashboard.store" });

const defaultColumnIds = [
  "id",
  "customer.name",
  "install_address.street",
  "distribution.grid_area",
  `core.heat_energy_sum`,
  `core.volume_sum`,
  `core.returntemp_flowweighted_avg`,
];

const availableBlocks = [
  "building",
  "customer",
  "distribution",
  "install_address",
  "pricing",
  "installation",
];

type DataRow = {
  [key: string]: any;
  sub_id: string;
};

export type ColumnPeriod = (typeof COLUMN_PERIOD)[keyof typeof COLUMN_PERIOD];

type State = {
  fetched: boolean;
  ready: boolean;
  data: Record<string, any> | null;
  error: string | null;
  rows: DataRow[];
  columns: any[];
  tableColumns: ColumnMeta[];
  periodArgs: { year: number; month: number; isR12: boolean };
  activePeriod: ColumnPeriod;
  month: MonthOption;
  showSubstationDetails: boolean;
};

type Action = {
  setError: (error: string | null) => void;
  setActivePeriod: (activePeriod: ColumnPeriod) => void;
  setMonth: (month: MonthOption) => void;
  setShowSubstationDetails: (showSubstationDetails: boolean) => void;
  fetchData: () => Promise<void>;
  prepareRows: () => void;
};

type DashboardStore = State & Action;

const dashboardStore: StateCreator<
  DashboardStore,
  [["zustand/devtools", never]],
  [],
  DashboardStore
> = (set, get) => ({
  // Initial State
  fetched: false,
  ready: false,
  data: null,
  error: null,
  rows: [],
  activePeriod: COLUMN_PERIOD.year,
  month: null,
  showSubstationDetails: false,

  // Actions
  setError: (error) => set({ error }),
  setMonth: (month) => set({ month }),
  setActivePeriod: (activePeriod) => set({ activePeriod }),
  setShowSubstationDetails: (showSubstationDetails) => set({ showSubstationDetails }),

  fetchData: async () => {
    if (get().fetched) return;
    const { selectedNetworkId } = useNetworkStore.getState();
    if (!selectedNetworkId) {
      logger.error("Cannot fetch data without `selectedNetworkId`");
      return;
    }
    logger.debug("fetchData %j", get().periodArgs);

    set({ fetched: false, ready: false });
    try {
      const data = await queryClient.ensureQueryData(
        getInfoBlockQueryOptions({
          resource_type: "network_substations",
          resource_id: selectedNetworkId,
          block_names: generateBlockNames(get().columns, get().periodArgs),
        })
      );
      set({ data, fetched: true });
      get().prepareRows();
    } catch (err) {
      get().setError((err as Error).message);
    }
  },

  prepareRows: () => {
    if (!get().fetched || !get().data) return;
    const reader = blkReader(
      get().data,
      generateSpecs(get().columns, get().periodArgs),
      collectorBlockPath
    );
    const rows: DataRow[] = [];
    const currentSubstations = useNetworkStore.getState().currentSubstations;
    if (!currentSubstations) {
      throw Error("`currentSubstations` needed to populate rows");
    }
    for (const subId of currentSubstations.keys()) {
      rows.push({ ...reader(subId), sub_id: subId });
    }
    set({ rows, ready: true });
  },

  // Computed properties
  get columns() {
    if (!get()?.activePeriod) return [];
    return generateColumnsBySBT(
      get().activePeriod,
      availableBlocks,
      defaultColumnIds,
      (substationId) => {
        useNetworkStore.getState().setSelectedSubstationId(substationId);
        get().setShowSubstationDetails(true);
      },
      (_: string, index: string) => useNetworkStore.getState().currentSubstations?.get(index)
    );
  },

  get tableColumns() {
    if (!get()?.columns) return [];
    return createInfoBlockGridColumns(defaultColumnIds, get().columns);
  },

  get periodArgs() {
    return {
      year: useNetworkStore.getState().activeYear || 2023,
      month: 0,
      isR12: false,
    };
  },

  get yearOptions() {
    const year = useNetworkStore.getState().lpYear?.year;
    if (!year) return [];

    const options = [];
    for (let i = 0; i < 8; i++) {
      options.push(DateTime.fromObject({ year }).plus({ years: -1 * i }).year);
    }
    options.push(R12);
    return options;
  },
});

export default create<DashboardStore>()(
  zustandMiddlware(dashboardStore, {
    name: "dashboardStore",
  })
);
