import type { ColumnMeta } from "@core/types/common";

import { numberSort } from "./utils";

export const MIN_COLUMN_WIDTH = 240;

export function createInfoBlockGridColumns(defaultIds: string[], generatedColumns: ColumnMeta[]) {
  return generatedColumns.map(
    ({
      path = "",
      label = "",
      sublabel = "",
      info = "",
      translateOptions,
      type = "number",
      section = "",
      hideable = path !== "id",
      spec,
      disabled = false,
      align = undefined,
      periods,
      sortType = null,
      enableGrouping = true,
      filterVariant,
      size = MIN_COLUMN_WIDTH,
      minSize = MIN_COLUMN_WIDTH,
      grow: defaultGrow = true,
      columns,
      onClick,
    }) => {
      const isNumeric = type === "number";
      const isDefault = defaultIds.includes(path);
      const defaultSortType = isNumeric ? numberSort : "alphanumeric";
      const grow = isNumeric ? false : defaultGrow;

      return {
        id: path,
        accessorKey: path,
        header: label,
        align,
        columns: columns?.map((subCol: any) => ({
          id: `${path}.${subCol.id}`,
          accessorKey: `${path}.${subCol.id}`,
          align: (align || subCol.type === "number" ? "right" : "left") as
            | "right"
            | "left"
            | "center"
            | undefined,
          header: subCol.label,
          info: subCol.info,
          type: subCol.type,
          sortingFn: subCol.type === "number" ? numberSort : "alphanumeric",
          isVisible: isDefault,
          size: 120,
        })),
        sortingFn: sortType || defaultSortType,
        enableSorting: !columns?.length,
        enableGrouping,
        type,
        isVisible: isDefault,
        section,
        sublabel,
        info,
        translateOptions,
        hideable,
        spec,
        disabled,
        periods,
        filterVariant,
        minSize,
        size,
        grow,
        onClick,
      };
    }
  );
}
