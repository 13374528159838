import type { PaletteColorOptions } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import { defaultTheme as utilifeedTheme } from "@config/utilifeed.theme";

// - Primary Brand Color (Blue)
const utfPrimary = {
  main: "#081D23",
  dark: "#081e25",
  light: "#336b73",
} as PaletteColorOptions;

// - Alternate Brand Color (Orange)
const utfSecondary = {
  main: "#DB742B",
  dark: "#A45720",
  light: "#E49760",
} as PaletteColorOptions;

// Palette & Typography -------------------------------------------------------
let baseTheme = createTheme(utilifeedTheme, {
  palette: {
    primary: utilifeedTheme.palette.augmentColor({ color: utfPrimary, name: "primary" }),
    secondary: utilifeedTheme.palette.augmentColor({ color: utfSecondary, name: "secondary" }),
  },
});

// Overrides ------------------------------------------------------------------
baseTheme = createTheme(baseTheme, {
  components: {
    // Application-wide reset & custom styles
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // Global overrides for the Scrollbar
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "1.143em",
            height: "1.143em",
            borderRadius: 0,
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            borderRadius: 0,
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 0,
            backgroundColor: baseTheme.palette.primary.light,
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: baseTheme.palette.primary.light,
          },
        },
      },
    },
  },
});

export const defaultTheme = baseTheme;
