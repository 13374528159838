import type { ReactNode } from "react";
import { AppBar, Box, IconButton, Tooltip, type AppBarProps, type BoxProps } from "@mui/material";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

import { BAR_HEIGHT } from "@config";

import { FontAwesomeSvgIcon } from "../FontAwesomeSvgIcon";

type SubHeaderProps = {
  /** to be using as data-testid */
  id?: string;
  /** content to be rendered within */
  children?: ReactNode;
  /** component to be rendered at the end */
  endComponent?: ReactNode;
  /** Click handler for info button */
  onInfoButtonClick?: () => void;
} & AppBarProps;

/**
 * Secondary header bar component.
 *
 * This component is used to hold any page specific controls.
 * It also houses the link to the wiki page for the page.
 *
 * @param {React.ReactNode} props.children - The children to render within
 * @param {React.ReactNode} [props.endComponent=null] - An optional component to render at the end of
 * @param {() => void} [props.onInfoButtonClick] - An optional callback function to be executed when the info button is clicked.
 * @param {string} [props.id] - An optional ID
 */
function SubHeader({
  children,
  endComponent = null,
  onInfoButtonClick,
  id,
}: Readonly<SubHeaderProps>) {
  return (
    <AppBar position="relative" sx={{ backgroundColor: ({ palette }) => palette.primary.light }}>
      <Box
        minHeight={BAR_HEIGHT}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        data-testid={`SubHeader-${id}`}
      >
        {/* Left Side Components */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          height="100%"
          flexGrow={1}
          gap={1}
          pl={3}
          pr={1}
          py={1}
          style={{ flexFlow: "wrap" }}
        >
          {children}
        </Box>

        {/* Right Side Components */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          height="100%"
        >
          {endComponent && (
            <Box
              borderLeft={1}
              borderColor="border.divider.main"
              height="inherit"
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              px={1}
              py={1}
            >
              {endComponent}
            </Box>
          )}
          <Box borderLeft={1} borderColor="border.divider.main" height="inherit">
            {onInfoButtonClick && (
              <IconButton
                onClick={onInfoButtonClick}
                color="inherit"
                size="large"
                sx={{ height: "100%", p: 1.7 }}
              >
                <Tooltip title="Information">
                  <FontAwesomeSvgIcon icon={faCircleInfo} sx={{ fontSize: 20 }} />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
}

export default SubHeader;

type SubHeaderSectionProps = {
  children: ReactNode;
} & BoxProps;

export function SubHeaderSection({ children, ...props }: Readonly<SubHeaderSectionProps>) {
  return (
    <Box
      color="grey.50"
      borderRight={2}
      borderColor="primary.light"
      height="inherit"
      px={2}
      py={1}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Box>
  );
}
