import { Info } from "luxon";

import { getValueFromBlock, SUBSTATION_BLOCK_TYPES as SBT } from "@config/blocks";
import { type BlockDefinition } from "@core";

export type Trend = { current: number; previous: number };
export type MonthlyData = {
  first: (number | undefined)[];
  second: (number | undefined)[];
  months: string[];
};
export type YearlyData = {
  first: (number | undefined)[];
  second: (number | undefined)[];
  years: number[];
};

export function appendYearlyReadings(
  reading: YearlyData,
  year: number,
  epCoreValue?: number,
  coreValue?: number
) {
  if (reading.years.length === 0 && !coreValue && !epCoreValue) {
    return;
  }

  reading.years.push(year);
  reading.second.push(coreValue);
  reading.first.push(epCoreValue);
}

export const blockTrend = (current?: number, prev?: number) =>
  typeof current === "number" && typeof prev === "number"
    ? ((current - prev) / prev) * 100
    : undefined;

export function getMonthlyData(
  BLOCK_TYPE: string,
  requiredMonths: { year: number; month: number; epyear: number }[],
  infoBlockData?: Record<string, BlockDefinition>,
  resourceId?: string
) {
  const reading: MonthlyData = {
    first: [],
    second: [],
    months: [],
  };
  if (!resourceId || !infoBlockData) return reading;
  for (const cMonth of requiredMonths) {
    const coreBlock = SBT.core.to_block_name({ year: cMonth.year, month: cMonth.month });
    const epBlock = SBT.epcore_normalized_monthly.to_block_name({
      syear: cMonth.epyear,
      smonth: 12,
      month: cMonth.month,
    });
    const coreValue = getValueFromBlock(infoBlockData, coreBlock, resourceId, BLOCK_TYPE);
    const epCoreValue = getValueFromBlock(infoBlockData, epBlock, resourceId, BLOCK_TYPE);
    const monthVal = Info.months("short")[cMonth.month - 1];
    reading.second.unshift(coreValue);
    reading.first.unshift(epCoreValue);
    reading.months.unshift(monthVal);
  }
  return reading;
}

export function getYearlyData(
  BLOCK_TYPE: string,
  requiredYears: number[],
  infoBlockData?: Record<string, BlockDefinition>,
  resourceId?: string
) {
  const reading: YearlyData = {
    first: [],
    second: [],
    years: [],
  };
  if (!resourceId || !infoBlockData) return reading;
  for (const cYear of requiredYears) {
    const coreBlock = SBT.core.to_block_name({ year: cYear, month: null });
    const epBlock = SBT.epcore_normalized.to_block_name({ year: cYear, month: 12 });
    const coreValue = getValueFromBlock(infoBlockData, coreBlock, resourceId, BLOCK_TYPE);
    const epCoreValue = getValueFromBlock(infoBlockData, epBlock, resourceId, BLOCK_TYPE);

    appendYearlyReadings(reading, cYear, epCoreValue, coreValue);
  }
  return reading;
}
