import { useEffect, useMemo } from "react";
import {
  Alert,
  AlertTitle,
  AppBar,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { faNetworkWired, faRightFromBracket } from "@fortawesome/pro-light-svg-icons";
import { faBuilding, faComment, faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";

import { getNetworksQueryOptions, logger, useNetworkStore } from "@core";
import { useLocalStorage } from "@hooks";
import { LoaderScreen } from "@shared/LoaderScreen";

const pageTitle = "PropAI";

const AppLayout = () => {
  const theme = useTheme();
  const allNetworks = useNetworkStore((s) => s.networks);
  const ready = useNetworkStore((s) => s.ready);
  const networkError = useNetworkStore((s) => s.error);
  const selectedNetworkId = useNetworkStore((s) => s.selectedNetworkId);
  const selectNetwork = useNetworkStore((s) => s.selectNetwork);
  const [defaultNetwork, setDefaultNetwork] = useLocalStorage("defaultNetwork", "");
  const { auth } = Route.useRouteContext();

  let filteredNetworks = allNetworks;
  // Temporary workaround to filter network list shown on production to only two networks.
  // Demo purpose only, to be removed.
  if (import.meta.env.PROD && import.meta.env.MODE !== "qa" && import.meta.env.MODE !== "test")
    filteredNetworks = allNetworks.filter(
      ({ name, uid }) =>
        name === "demo_network_500" ||
        // Oslo on Production
        uid === "956e1dda-9753-46e8-969e-f639ddfffeee"
    );

  useEffect(() => {
    if (ready || selectedNetworkId) return;
    (async () => {
      const currentNetwork = await selectNetwork(String(defaultNetwork));
      // save selected network to browser store
      if (currentNetwork && !defaultNetwork) setDefaultNetwork(currentNetwork);
    })();
  }, [ready, defaultNetwork, selectNetwork, setDefaultNetwork, selectedNetworkId]);

  const outletRenderer = ready ? <Outlet /> : <LoaderScreen />;

  logger.debug("RENDER <AppLayout> %j", { ready, selectedNetworkId });
  return (
    <>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "primary.dark",
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          color: "white",
          height: "5.14rem",
          py: 1,
        }}
      >
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            {/* Navigation */}
            <Grid item display="flex" justifyContent="flex-end" alignItems="center">
              <Box>
                <FontAwesomeIcon icon={faBuilding} />
                <Typography pl={1} variant="button">
                  Properties
                </Typography>
              </Box>
              <Box ml={3}>
                <FontAwesomeIcon icon={faFileInvoice} />
                <Typography pl={1} variant="button">
                  Report
                </Typography>
              </Box>
              <Box ml={3}>
                <FontAwesomeIcon icon={faComment} />
                <Typography pl={1} variant="button">
                  Chat
                </Typography>
              </Box>
            </Grid>
            {/* Brand */}
            <Grid item flex={1}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img src="/hafslund.webp" alt="Hafslund | PropAI" height="32px" />
                <Typography
                  variant="h4"
                  data-testid="page-title"
                  fontSize={32}
                  fontWeight={400}
                  fontFamily="Roboto"
                  ml={2}
                >
                  {pageTitle}
                </Typography>
              </Box>
            </Grid>
            {/* Network Selection & Logout */}
            <Grid item>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <FontAwesomeIcon icon={faNetworkWired} />
                {!ready ? (
                  <Typography variant="body1" p={2} sx={{ color: "white" }}>
                    Loading Networks...
                  </Typography>
                ) : (
                  <Box display="flex" alignItems="center">
                    {/* Networks Dropdown */}
                    <Select
                      labelId="network-select-label"
                      value={selectedNetworkId ?? -1}
                      onChange={(e) => selectNetwork(String(e.target.value))}
                      sx={{ color: "white", svg: { fill: "white" } }}
                    >
                      {filteredNetworks?.map(({ uid, display_name }) => (
                        <MenuItem key={uid} value={uid}>
                          {display_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
            </Grid>
            {/* Logout */}
            <Grid item>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Tooltip title="Logout">
                  <IconButton size="small" onClick={() => auth?.logout()}>
                    <FontAwesomeIcon color="white" icon={faRightFromBracket} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* Main/Route Content */}
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100vh",
          color: "grey.800",
        }}
      >
        {/* Body */}
        <Box
          component="section"
          height="100%"
          overflow="auto"
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          {networkError ? (
            <Box p={4}>
              <Alert severity="error">
                <AlertTitle>{networkError.message}</AlertTitle>
                This likely means you dont have access to any network. Please contact your admin.
              </Alert>
            </Box>
          ) : (
            outletRenderer
          )}
        </Box>
      </Box>
    </>
  );
};

export const Route = createFileRoute("/app/_layout")({
  component: AppLayout,
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: ({ context, location }) => {
    // If the user is logged out, redirect them to the login page
    if (!context.auth?.isAuthenticated && !context.auth?.isLoading) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
  // Fancy little piece of code that ensures the networks data is loaded,
  // before rendering the component
  loader: async ({ context }) => {
    try {
      const networks = await context.queryClient.ensureQueryData(getNetworksQueryOptions());
      useNetworkStore.getState().setNetworks(networks);
    } catch (error) {
      logger.error("Error loading networks", error);
    }
  },
});
