import { create, type StateCreator } from "zustand";

import { COLUMN_PERIOD } from "@config";
import { blkReader, collectorBlockPath, SUBSTATION_BLOCK_TYPES as SBT } from "@config/blocks";
import type { BlockDefinition, InfoData } from "@core";
import {
  logger as baseLogger,
  getInfoBlockQueryOptions,
  queryClient,
  range,
  useNetworkStore,
} from "@core";
import { zustandMiddlware } from "@core/stores/middleware";
import { generateColumnsBySBT, generateSpecs } from "@core/utils/columns/utils";

const logger = baseLogger.getSubLogger({ name: "property.store" });

type State = {
  fetching: boolean;
  needsFetch: boolean;
  year: string | null;
  period: string;
  duration: string;
  data: Record<string, BlockDefinition>;
  reader: (substationId: string) => InfoData | null;
  columns: unknown[];
  blocks: string[];
  error: Error | null;
  resourceId?: string;
  requiredYears: number[];
  requiredMonths: { epyear: number; year: number; month: number }[];
};

type Action = {
  setPending: (fetching: boolean) => void;
  setYear: (year: string | null) => void;
  setPeriod: (period: string) => void;
  setDuration: (duration: string) => void;
  setError: (error: Error | null) => void;
  fetch: (substationId: string) => Promise<void>;
  refetch: () => void;
};

type PropertyStore = State & Action;

const DURATIONS = [
  { value: "7", label: "7 days" },
  { value: "14", label: "14 days" },
  { value: "30", label: "30 days" },
];

const availableBlocks = [
  "building",
  "customer",
  "distribution",
  "install_address",
  "epcore_normalized",
];

const propertyStore: StateCreator<
  PropertyStore,
  [["zustand/devtools", never]],
  [],
  PropertyStore
> = (set, get) => ({
  needsFetch: true,
  fetching: false,
  error: null,
  year: null,
  period: COLUMN_PERIOD.year,
  duration: DURATIONS[0].value,
  data: {},
  reader: () => null,

  refetch: () => set({ needsFetch: true, data: {} }),
  setPending: (fetching) => set({ fetching }),
  setYear: (year) => set({ year }),
  setPeriod: (period) => set({ period }),
  setDuration: (duration) => set({ duration }),
  setError: (error) => set({ error }),

  get columns() {
    return generateColumnsBySBT(COLUMN_PERIOD.year, availableBlocks);
  },
  get resourceId() {
    return useNetworkStore.getState().selectedSubstationId;
  },
  get requiredYears() {
    const years: number[] = [];
    const { lpYear } = useNetworkStore.getState();
    if (lpYear === null) return years;

    years.push(lpYear.year);
    years.push(lpYear.plus({ years: -1 }).year);

    // last 5 year
    for (const yearsBack of range(4, -1, -1)) {
      const cYear = lpYear.plus({ years: -1 * yearsBack }).year;
      years.push(cYear);
    }
    years.sort((a, b) => a - b);
    return [...new Set(years)];
  },
  get requiredMonths() {
    const { lpYear, lpMonth } = useNetworkStore.getState();
    if (!lpYear || !lpMonth) return [];
    const reqMonths: State["requiredMonths"] = [];
    for (const monthsBack of range(0, 12)) {
      const cMonth = lpMonth.plus({ months: -1 * monthsBack });
      reqMonths.push({ epyear: lpYear.year, year: cMonth.year, month: cMonth.month });
    }
    return reqMonths;
  },
  get blocks() {
    const { lpYear } = useNetworkStore.getState();
    if (!lpYear || !get()?.requiredYears) return [];

    const blocks = [
      SBT.install_address.to_block_name(),
      SBT.building.to_block_name(),
      SBT.customer.to_block_name(),
      SBT.core.to_block_name({ year: lpYear.year }),
    ];

    for (const year of get().requiredYears) {
      blocks.push(SBT.core.to_block_name({ year, month: null }));
      blocks.push(SBT.epcore_normalized.to_block_name({ year, month: 12 }));
    }

    for (const { year, month, epyear } of get().requiredMonths) {
      blocks.push(SBT.core.to_block_name({ year, month }));
      blocks.push(
        SBT.epcore_normalized_monthly.to_block_name({
          syear: epyear,
          smonth: 12,
          month,
        })
      );
    }

    return [...new Set(blocks)];
  },

  fetch: async (substationId) => {
    const { fetching, blocks } = get();
    const { selectedNetworkId, lpYear, lpMonth } = useNetworkStore.getState();

    if (fetching || !substationId || !lpYear || !lpMonth) return;

    logger.debug("fetching");
    set({ fetching: true });
    let data;
    try {
      data = (await queryClient.ensureQueryData(
        getInfoBlockQueryOptions({
          resource_type: "substation",
          resource_id: substationId,
          network_id: selectedNetworkId,
          block_names: blocks,
        })
      )) as State["data"];

      set({ data, fetching: false, needsFetch: false });

      set({
        reader: blkReader(
          data,
          generateSpecs(generateColumnsBySBT(COLUMN_PERIOD.year, availableBlocks), {
            year: lpYear.year,
          }),
          collectorBlockPath
        ),
      });
    } catch (error) {
      set({ error: error as Error, fetching: false });
    }
  },
});

export const usePropertyStore = create<PropertyStore>()(
  zustandMiddlware(propertyStore, {
    name: "propertyStore",
  })
);
