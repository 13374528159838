/* eslint-disable sonarjs/cognitive-complexity */
import type { AxiosRequestConfig } from "axios";
import type { DateTime } from "luxon";

import {
  blkReader,
  NETWORK_BLOCK_TYPES,
  SUBSTATION_BLOCK_TYPES as SBT,
  transformMeterDataToV3Block,
  WEATHER_BLOCK,
} from "@config/blocks";
import URLS from "@config/urls";
import { logger as baseLogger } from "@core/logger";
import { formatDateAPI } from "@core/utils";
import { splitDf, splitDfV4 } from "@core/utils/infoblock";
import { urlWithParams } from "@core/utils/urlWithParams";

import { type BlockDefinition, type WeatherData } from "../models/network.model";
import axiosClient from "./apiClient";
import { MdslApi } from "./mdsl.api";

const logger = baseLogger.getSubLogger({ name: "network.api" });

// Define a common interface for parameters with optional resource_uids
type BaseParams = {
  block_names: string[];
  resource_uids?: string[];
  page_size?: number;
};

// Extend the base interface for specific API calls
type GetNetworkInfoBlockV4Params = {
  resource_ids: string[];
  page_size?: number;
} & BaseParams;

type GetNetworkSubstationInfoBlockV4Params = {
  network_uid: string;
} & BaseParams;

type GetClusterSubstationInfoBlockV4Params = {
  cluster_uid: string;
} & BaseParams;

type GetNetworkClusterInfoBlockV4Params = {
  network_uid: string;
} & BaseParams;

export type GetWeatherDataParams = {
  resource_id: string;
  resource_type: string;
  network_id: string | null;
  date_min: DateTime;
  date_max: DateTime;
  coordinates: [number, number] | null;
  metrics: string;
};

type WeatherResponse = { columns: string[]; data: (string | number)[][] };

export type GetSubstationMeterDataParams = {
  network_uid: string;
  resource_uids?: string[];
  stage?: string;
  meter?: string;
  components?: string[];
  flags?: string[];
  range?: Record<string, string>;
  ts_end: DateTime | null;
  ts_start: DateTime | null;
  page_size?: number;
};

export type GetInfoBlocksParams = {
  resource_type: string;
  network_id?: string;
  resource_id: string;
  block_names: string[];
  block_definitions?: { [key: string]: unknown };
};

const { get } = axiosClient;

export class NetworkApi extends MdslApi {
  async getNetworkCoordinates(
    networkUid: string | undefined
  ): Promise<{ lat: number; lon: number }> {
    if (!networkUid) {
      throw new Error("Network ID is required");
    }
    // /networks/info-data?uid[in]=networkUid&block_name
    return get(`/networks/info-data`, {
      params: {
        "uid[in]": networkUid,
        "block_name[in]": "location",
      },
      ...(await this.axiosDefaults),
    }).then((res) => {
      return {
        lat: res.data.data.collection[0].blockData.coordinates.split(",")[0],
        lon: res.data.data.collection[0].blockData.coordinates.split(",")[1],
      };
    });
  }

  async getWeatherData({
    resource_id,
    resource_type,
    network_id,
    date_min,
    date_max,
    coordinates,
    metrics,
  }: GetWeatherDataParams): Promise<WeatherResponse | null> {
    let latitude_longitude = coordinates;
    logger.trace("getWeatherData", {
      resource_id,
      resource_type,
      network_id,
      date_min,
      date_max,
      coordinates,
    });
    if (!date_min) throw new Error("`date_min` is required");
    if (!date_max) throw new Error("`date_max` is required");

    try {
      if ((coordinates === null || coordinates === undefined) && network_id !== null) {
        if (!resource_id) throw new Error("`resource_id` is required");
        if (!resource_type) throw new Error("`resource_type` is required");

        const infoResp = await this.getInfoBlocks({
          resource_id,
          resource_type,
          network_id,
          block_names: [SBT.location.to_block_name()],
        });

        const infoRespReader = blkReader(infoResp, [
          ["name", [SBT.location.to_block_name(), SBT.location.col.weather_coordinates], "coords"],
        ]);
        latitude_longitude = infoRespReader(resource_id)?.coords;
      }

      if (latitude_longitude) {
        const response = await get(
          `/weather-data/lon/${latitude_longitude[1]}/lat/${latitude_longitude[0]}`,
          {
            baseURL: URLS.weather,
            params: {
              date_min: formatDateAPI(date_min),
              date_max: formatDateAPI(date_max),
              metrics,
              page_size: 0,
            },
            headers: { authorization: "WDSL wdsl=NEGEM6HKDM8PQENLOE9OUK3B614A7TZX" },
          }
        );

        const jsonArr = response.data;
        if (Array.isArray(jsonArr) && jsonArr.length > 0) {
          const columns = ["datetime"];
          const data: WeatherResponse["data"] = [];
          // Convert each metric data into flattened array
          jsonArr.forEach((collection, i) => {
            const colIndex = i + 1; // first index is always "datetime"
            const metricData = collection.data;
            columns.push(collection.metric);
            metricData.forEach((dataRow: WeatherData, dataIndex: number) => {
              if (!data[dataIndex]) data[dataIndex] = [];
              data[dataIndex][0] = dataRow.datetime;
              data[dataIndex][colIndex] = dataRow.value;
            });
          });
          return splitDf({ columns, data }, { weather: WEATHER_BLOCK })[0][1];
        }
        throw Error("Unable to get weather data");
      }

      logger.error("Unable to get weather data, coordinates not available");
      return null;
    } catch (err) {
      const errMsg = (err as Error).message;
      logger.error("Error getting weather data:", errMsg);
      throw Error(`Unable to get weather data: ${errMsg}`);
    }
  }

  async getSubstationMeterData({
    resource_uids = [],
    network_uid,
    stage = "",
    meter = "",
    components = [],
    flags = [],
    range = {},
    ts_end,
    ts_start,
    page_size = 0,
  }: GetSubstationMeterDataParams): Promise<BlockDefinition | null> {
    logger.trace("getSubstationMeterData %j", {
      resource_uids,
      network_uid,
      stage,
      ts_start,
      ts_end,
    });
    let currentPage = 1;
    let haveMore = true;
    const params: Record<string, string> = {
      "component[in]": components.join(","),
      "stage[in]": stage,
      "meter[in]": meter,
      page_size: page_size.toString(),
      "datetime[ge]": ts_start?.toISO() ?? "",
      "datetime[lt]": ts_end?.toISO() ?? "",
    };
    for (const [key, value] of Object.entries(range)) {
      params[key] = value;
    }
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }
    if (flags.length > 0) {
      params["flag[in]"] = flags.join(",");
    }

    try {
      const readings = new Map();
      while (haveMore) {
        params.page = currentPage.toString();
        const url = urlWithParams(
          `${URLS.mdslv4}/networks/${network_uid}/substations/meter-data`,
          params
        );
        const res = await get(url, await this.axiosDefaults);
        if (res.status === 200 && res.data) {
          const resJson = res.data;
          if (resJson.data && resJson.error === null) {
            for (const blk of resJson.data.collection) {
              const blk_id = `${blk.uid}-${blk.component}-${blk.stage}`;
              if (readings.has(blk_id)) {
                const prevReading = readings.get(blk_id);
                for (const iblk of blk.data) {
                  prevReading.data.push(iblk);
                }
              } else {
                readings.set(blk_id, blk);
              }
            }
            if (resJson.data.has_next_page) {
              currentPage += 1;
            } else {
              haveMore = false;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      // @ts-expect-error transformMeterDataToV3Block must be migrated to TS
      return transformMeterDataToV3Block({
        resp: { collection: Array.from(readings.values()) },
        ts_start,
        ts_end,
        components,
      });
    } catch (err) {
      logger.error("getSubstationMeterData", err);
      return null;
    }
  }

  async getNetworkResourcesV4(networkUid: string) {
    logger.trace("getNetworkResources", networkUid);
    if (!networkUid) {
      throw new Error("Network ID is required");
    }
    try {
      const res = await get(`/networks/${networkUid}`, await this.axiosDefaults);
      if (res.status === 200 && res.data) {
        return res.data.data;
      }
      logger.trace("unable to get network resources", res.status);
    } catch (err) {
      console.error(err);
    }
    return null;
  }

  async getNetworkInfoBlockV4({
    resource_ids,
    block_names,
    page_size = 0,
  }: GetNetworkInfoBlockV4Params) {
    logger.trace("getNetworkInfoBlockV4 %j", resource_ids);
    let currentPage = 1;
    let haveMore = true;
    const params: AxiosRequestConfig["params"] = {
      "uid[in]": resource_ids.join(","),
      "block_name[in]": block_names.join(","),
      page: 0,
      page_size,
    };
    try {
      const collection = [];
      while (haveMore) {
        params.page = currentPage;
        const res = await get("/networks/info-data", { params, ...(await this.axiosDefaults) });
        if (res.status === 200 && res.data) {
          for (const blk of res.data.data.collection) {
            collection.push(blk);
          }
          if (res.data.data.has_next_page) {
            currentPage += 1;
          } else {
            haveMore = false;
          }
        } else {
          return null;
        }
      }
      logger.trace("getNetworkInfoBlockV4");
      return { collection };
    } catch (err) {
      console.error(err);
    }
    return null;
  }

  async getNetworkSubstationInfoBlockV4({
    network_uid,
    block_names,
    resource_uids = [],
    page_size = 0,
  }: GetNetworkSubstationInfoBlockV4Params) {
    logger.trace("getNetworkSubstationInfoBlockV4", network_uid);
    let currentPage = 1;
    let haveMore = true;
    const params: AxiosRequestConfig["params"] = {
      "block_name[in]": block_names.join(","),
      page: 1,
      page_size,
    };
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }
    try {
      const collection = [];
      while (haveMore) {
        params.page = currentPage;
        const res = await get(`/networks/${network_uid}/substations/info-data`, {
          params,
          ...(await this.axiosDefaults),
        });
        if (res.status === 200 && res.data) {
          for (const blk of res.data.data.collection) {
            collection.push(blk);
          }
          if (res.data.data.has_next_page) {
            currentPage += 1;
          } else {
            haveMore = false;
          }
        } else {
          return null;
        }
      }
      return { collection };
    } catch (err) {
      return null;
    }
  }

  async getClusterSubstationInfoBlockV4({
    cluster_uid,
    block_names,
    resource_uids = [],
    page_size = 0,
  }: GetClusterSubstationInfoBlockV4Params) {
    logger.trace("getClusterSubstationInfoBlockV4", cluster_uid);
    let currentPage = 1;
    let haveMore = true;
    const params: AxiosRequestConfig["params"] = {
      "block_name[in]": block_names,
      page: 1,
      page_size,
    };
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }
    try {
      const collection = [];
      while (haveMore) {
        params.page = currentPage;
        const res = await get(`/clusters/${cluster_uid}/substations/info-data`, {
          params,
          ...(await this.axiosDefaults),
        });
        if (res.status === 200 && res.data) {
          for (const blk of res.data.data.collection) {
            collection.push(blk);
          }
          if (res.data.data.has_next_page) {
            currentPage += 1;
          } else {
            haveMore = false;
          }
        } else {
          return null;
        }
      }
      return { collection };
    } catch (err) {
      return null;
    }
  }

  async getNetworkClusterInfoBlockV4({
    network_uid,
    block_names,
    resource_uids = [],
    page_size = 0,
  }: GetNetworkClusterInfoBlockV4Params) {
    logger.trace("getNetworkClusterInfoBlockV4", network_uid);
    let currentPage = 1;
    let haveMore = true;
    const params: AxiosRequestConfig["params"] = {
      "block_name[in]": block_names,
      page: 1,
      page_size,
    };
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }
    try {
      const collection = [];
      while (haveMore) {
        params.page = currentPage;
        const res = await get(`/networks/${network_uid}/clusters/info-data`, {
          params,
          ...(await this.axiosDefaults),
        });
        if (res.status === 200 && res.data) {
          for (const blk of res.data.data.collection) {
            collection.push(blk);
          }
          if (res.data.data.has_next_page) {
            currentPage += 1;
          } else {
            haveMore = false;
          }
        } else {
          return null;
        }
      }
      return { collection };
    } catch (err) {
      return null;
    }
  }

  async getClusterSubstations(clusterUid: string) {
    try {
      const res = await get(`/clusters/${clusterUid}`, await this.axiosDefaults);
      if (res.status === 200 && res.data && res.data.error === null) {
        return res.data.data;
      }
      return null;
    } catch (err) {
      logger.error("getClusterSubstations", err);
      return null;
    }
  }

  async getInfoBlocks({
    resource_type,
    network_id,
    resource_id,
    block_names,
    block_definitions,
  }: GetInfoBlocksParams) {
    let data = null;
    try {
      let blk_def = block_definitions;
      if (block_names.length <= 0) return null;

      logger.trace("getInfoBlocks %s", {
        resource_type,
        network_id,
        resource_id,
        block_names,
        block_definitions,
      });

      data = await this.getDataByResourceType({
        resource_type,
        network_id,
        resource_id,
        block_names,
      });

      if (data) {
        blk_def = this.getBlockDefinitionsByResourceType(resource_type, blk_def);
      }

      if (data?.collection) {
        // @ts-expect-error legacy
        const splitData = splitDfV4(data.collection, blk_def);
        const blocks: { [key: string]: unknown } = {};
        for (const block_name of block_names) {
          blocks[block_name] = new Map(splitData).get(block_name);
        }
        return blocks;
      }
    } catch (err) {
      logger.trace("getInfoBlocks failed");
      console.error(err as Error);
    }

    return null;
  }

  private async getDataByResourceType({
    resource_type,
    network_id,
    resource_id,
    block_names,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: Omit<GetInfoBlocksParams, "block_definitions">): Promise<any | null> {
    logger.trace("getDataByResourceType", resource_type);
    switch (resource_type) {
      case "network":
        return this.getNetworkInfoBlockV4({
          resource_ids: [resource_id],
          block_names,
        });
      case "network_substations":
        return this.getNetworkSubstationInfoBlockV4({
          network_uid: resource_id,
          block_names,
        });
      case "cluster_substations":
        return this.getClusterSubstationInfoBlockV4({
          cluster_uid: resource_id,
          block_names,
        });
      default:
    }

    if (resource_type !== "substation" && resource_type !== "cluster") {
      throw Error("No valid `resource_type` provided");
    }

    if (typeof network_id === "undefined") {
      throw Error("`network_id` is required for `substation` and `cluster` resources");
    }

    switch (resource_type) {
      case "substation":
        return this.getNetworkSubstationInfoBlockV4({
          network_uid: network_id,
          resource_uids: [resource_id],
          block_names,
        });
      case "cluster":
        return this.getNetworkClusterInfoBlockV4({
          network_uid: network_id,
          resource_uids: [resource_id],
          block_names,
        });
      default:
    }
  }

  private getBlockDefinitionsByResourceType(
    resource_type: string,
    blk_def: GetInfoBlocksParams["block_definitions"]
  ): GetInfoBlocksParams["block_definitions"] {
    logger.trace("getBlockDefinitionsByResourceType", resource_type, blk_def);
    switch (resource_type) {
      case "network":
        return blk_def || NETWORK_BLOCK_TYPES;
      case "network_substations":
      case "cluster_substations":
      case "substation":
      case "cluster":
        return blk_def || SBT;
      default:
        return blk_def;
    }
  }
}

export default new NetworkApi();
