/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseCard } from "@shared";

import GraphColCompare from "./GraphColCompare";
import GraphTop from "./GraphTop";

export type Series = {
  name: string;
  data: (number | undefined)[] | undefined;
  label?: string;
};

type ColCompareCardProps = {
  title: string;
  errorMsg?: string;
  unit: string;
  legend: any[];
  categories?: (string | number)[];
  series1: Series;
  series2: Series;
  loading: boolean;
};

export default function ColCompareCard(props: ColCompareCardProps) {
  const { title, errorMsg, unit, legend, categories, series1, series2, loading = true } = props;
  return (
    <BaseCard loading={loading} title={title} error={!!errorMsg} errorMessage={errorMsg}>
      <GraphTop legend={legend} />
      <GraphColCompare
        series1={{
          ...series1,
          // @ts-expect-error later
          pointWidth: 12,
        }}
        series2={{
          ...series2,
          // @ts-expect-error later
          type: "line",
          linewidth: 3,
          marker: {
            enabled: true,
            radius: 6,
          },
          pointPlacement: 0,
        }}
        yUnit={unit}
        categories={categories}
        loading={loading}
      />
    </BaseCard>
  );
}
