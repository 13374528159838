/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosDefaults, CreateAxiosDefaults } from "axios";

import { toCamelCase } from "@core/utils/toCamelCase";
import { toSnakeCase } from "@core/utils/toSnakeCase";

const MDSL_V4_URL = import.meta.env?.["REACT_APP_MDSL_TS_V4_URL"];
const BASE_URL = import.meta.env?.["REACT_APP_MDSL_V4_URL"];

type CustomAxiosClientConfig = {
  shouldCamelCaseResponse: boolean;
} & CreateAxiosDefaults;

export type CustomAxiosDefaults = {
  shouldCamelCaseResponse?: boolean;
} & AxiosDefaults<any>;

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  shouldCamelCaseResponse: false,
} as CustomAxiosClientConfig);

// Middleware goes here

// Add a response interceptor and camelCase return data (for JS)
axiosClient.interceptors.response.use(
  (response) => {
    // only change cases for MDSL_TS
    if ((response.config as CustomAxiosClientConfig).shouldCamelCaseResponse === false)
      return response;

    response.data = toCamelCase(response.data);
    return response;
  },
  (error) => {
    // only change cases for MDSL_TS
    if (error.response.config.shouldCamelCaseResponse === false) return error;

    if (error.response) {
      // only change cases for MDSL_TS
      if (error.response.config?.baseURL !== MDSL_V4_URL) return error;

      error.response.data = toCamelCase(error.response.data);
    } else {
      error.response = { data: null };
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor and snakeCase POST data (for django)
axiosClient.interceptors.request.use(
  (config) => {
    // only change cases for MDSL_TS
    if ((config as CustomAxiosClientConfig).shouldCamelCaseResponse === false) return config;

    config.params = toSnakeCase(config.params);
    config.data = toSnakeCase(config.data);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
