import { createFileRoute } from "@tanstack/react-router";

import { DashboardModule } from "@modules/Dashboard";

const App = () => {
  return <DashboardModule />;
};

export const Route = createFileRoute("/app/_layout/")({
  staticData: {
    pageTitle: "Dashboard",
  },
  component: App,
});
