import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { DateTime } from "luxon";

import { DATE_FORMAT, DATETIME_FORMAT_DEFAULT, DAY_MILIS, HOUR_MILIS } from "@config";
import { logger as baseLogger, useNetworkStore } from "@core";
import { formatNumberForLocale } from "@core/utils";
import { calculateDailyAverages } from "@core/utils/calculateDailyAverages";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import useMaintenanceStore from "@stores/maintenance.store";
import { useMeterSeries } from "@hooks/useMeterSeries";
import { BaseCard } from "@shared";
import { HighchartsBase } from "@shared/ui/analytics/charts";
import withErrorBoundary from "@shared/ui/withErrorBoundary";

const logger = baseLogger.getSubLogger({ name: "<FaultsAndActions/>" });

const MAX_CHART_HEIGHT = 550;
const CHART_ID = "meter";

function rowsInRanges({ start, end }: { start: DateTime; end: DateTime }) {
  return ({ ts }: { ts: number }) => ts >= start.toMillis() && ts <= end.toMillis();
}

export const FaultsAndActions = withErrorBoundary(() => {
  const theme = useTheme();
  const meterChartRef = useRef(null);
  const chartWidth = window.innerWidth - 50;
  const networkReady = useNetworkStore((s) => s.ready);
  const filterRange = useNetworkStore((s) => s.filterRange);
  const registerChart = useNetworkStore((s) => s.registerChart);

  const hasPartialData = useMaintenanceStore((s) => s.hasPartialData);
  const fetching = useMaintenanceStore((s) => s.fetching);
  const averaged = useMaintenanceStore((s) => s.averaged);
  const seriesData = useMaintenanceStore((s) => s.seriesData);
  const fetchData = useMaintenanceStore((s) => s.fetchData);

  const calculateDailySeries = useCallback(
    () => calculateDailyAverages(seriesData ?? []),
    [seriesData]
  );
  const seriesInExtremes = useMemo(() => {
    const { start, end } = filterRange;
    const series = averaged ? calculateDailySeries() : seriesData;
    if (!series || !start || !end) return [];
    return series.filter(rowsInRanges({ start, end }));
  }, [averaged, filterRange, seriesData, calculateDailySeries]);

  useEffect(() => {
    if (!networkReady || hasPartialData || fetching) return;
    void fetchData();
  }, [fetchData, hasPartialData, networkReady, fetching]);

  useEffect(() => {
    if (!meterChartRef.current) return;
    registerChart("primary", meterChartRef);
  }, [registerChart]);

  const { chartData, setVisibleSeries } = useMeterSeries(
    seriesInExtremes,
    averaged,
    filterRange,
    600
  );

  logger.trace("RENDER");

  return (
    <BaseCard title="FAULTS & ACTIONS" testId={`chart-${CHART_ID}-container`}>
      <Box p={2}>
        <HighchartsBase
          constructorType="stockChart"
          ref={meterChartRef}
          loading={!hasPartialData}
          showGraphUnit={false}
          disableExporting={true}
          chart={{
            id: CHART_ID,
            height: `${MAX_CHART_HEIGHT}px`,
            width: chartWidth,
          }}
          series={chartData.series}
          plotOptions={{
            series: {
              dataGrouping: {
                units: [
                  ["hour", [1, 3, 6, 12]],
                  ["day", [1]],
                  ["month", [1, 3, 6]],
                  ["year", null],
                ],
                forced: averaged ? true : undefined,
              },
              connectNulls: true,
              marker: {
                enabled: undefined, // This needs to be undefined to use the default value
                enabledThreshold: 2, // Enable if the distance between points is less than 2 x radius
                radius: 3,
              },
              events: {
                legendItemClick: createSeriesToggleHandler(setVisibleSeries),
              },
            },
          }}
          rangeSelector={{
            enabled: false,
          }}
          legend={{
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "top",
            y: 0,
            labelFormatter() {
              const { color, name, userOptions } = this;
              return `<div data-testid="${CHART_ID}__legend__${userOptions.id}" style="border-bottom: 3px solid ${color}">${name}</div>`;
            },
            itemStyle: {
              ...theme.typography.body2,
            },
          }}
          yAxis={chartData.yAxis}
          xAxis={{
            type: "datetime",
            labelFormatType: "dateOrTime",
            tickInterval: averaged ? DAY_MILIS : HOUR_MILIS,
            minRange: 1,
          }}
          navigator={{
            enabled: false,
          }}
          scrollbar={{
            enabled: false,
          }}
          tooltip={{
            split: true,
            formatter() {
              const x = DateTime.fromMillis(this.points[0].x).toFormat(
                averaged ? DATE_FORMAT : DATETIME_FORMAT_DEFAULT
              );
              const k = [`<b>${x}</b>`];
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              this.points.forEach((point: any) => {
                const unit = point.series.tooltipOptions.valueSuffix;
                const exactPointVal =
                  point.series.yData[point.series.xData.indexOf(point.x)] || point.y;
                const valY = formatNumberForLocale(exactPointVal);
                k.push(`<b> ${point.series.name} ${valY} ${unit}</b>`);
              });
              return k;
            },
          }}
        />
      </Box>
    </BaseCard>
  );
});

FaultsAndActions.displayName = "FaultsAndActions";
