import { Box, Typography } from "@mui/material";

function CardBottomText({
  startIcon,
  suffix,
  value,
  prefix,
  color = "secondary",
}: {
  startIcon: React.ReactNode;
  suffix: string;
  value: string;
  prefix?: string;
  color?: "primary" | "secondary" | "text.primary" | "text.secondary" | "inherit";
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "flex-start",
      }}
    >
      {startIcon}
      <Typography component="span" variant="h3" color={color} style={{ marginLeft: 8 }}>
        {prefix} {value} {suffix}
      </Typography>
    </Box>
  );
}

export default CardBottomText;
