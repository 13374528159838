import type { AxisSetExtremesEventObject } from "highcharts";
import { DateTime } from "luxon";
import { create, type StateCreator } from "zustand";

import {
  blkReader,
  HOUR_MILIS,
  INTERVAL_DAILY,
  INTERVAL_HOURLY,
  SUBSTATION_BLOCK_TYPES as SBT,
  SPEED_OPTIONS,
  SUBSTATION,
} from "@config";
import { columns } from "@config/consumption";
import {
  logger as baseLogger,
  getInfoBlockQueryOptions,
  getMeterData,
  getWeatherData,
  isValue,
  queryClient,
  useNetworkStore,
} from "@core";
import { zustandMiddlware } from "@core/stores/middleware";
import { type TimeSeries } from "@core/types/common";
import { deriveTimeSeries } from "@core/utils/deriveTimeSeries";

const logger = baseLogger.getSubLogger({ name: "maintenance.store" });

const DEFAULT_INTERVAL = INTERVAL_HOURLY;

export type DateRange = { start: DateTime | null; end: DateTime | null };

type SpeedRanges = { [label: string]: DateRange };

type MaintenanceState = {
  data: any;
  seriesData?: TimeSeries[];
  hasPartialData: boolean;
  averaged: boolean;
  fetching: boolean;
  interval: string;
  speedDates: SpeedRanges;
  speedOption: string;
  section?: string;
  error?: string;
};

type MaintenanceActions = {
  onFilterInputChange: (newStartDate: DateTime, newEndDate: DateTime) => void;
  onSpeedOptionChange: (nextOption: string) => void;
  onIntervalChange: (nextInterval: string) => void;
  onNavigatorExtremesUpdated: (event: AxisSetExtremesEventObject) => void;
  setNavigatorExtremes: (startTs: number, endTs: number) => void;
  fetchData: () => Promise<void>;
  prepareSeriesData: (data: any) => void;
  setError: (error: string) => void;
};

type MaintenanceStore = MaintenanceState & MaintenanceActions;

const maintenanceStore: StateCreator<
  MaintenanceStore,
  [["zustand/devtools", never]],
  [],
  MaintenanceStore
> = (set, get) => ({
  fetching: false,
  hasPartialData: false,
  averaged: false,
  data: {},
  seriesData: undefined,
  interval: DEFAULT_INTERVAL,
  speedOption: "",

  get speedDates() {
    const { fetchedRange } = useNetworkStore.getState();
    const fetchedRangeEnd = fetchedRange?.end;
    if (!fetchedRangeEnd) return [] as unknown as SpeedRanges;

    const dates: SpeedRanges = {};
    SPEED_OPTIONS.forEach(({ value, label, type }) => {
      const end = fetchedRangeEnd.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const start = end
        .plus({ [type]: value * -1 })
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      dates[label] = { start, end };
    });

    logger.debug("speedDates %j", dates);
    return dates;
  },

  setError: (error) => set({ error }, undefined, "setError"),
  onFilterInputChange: (newStart, newEnd) => {
    if (!newEnd || !newStart) return;
    set({ speedOption: "" });
    get().setNavigatorExtremes(newStart.toMillis(), newEnd.toMillis());
  },

  onSpeedOptionChange: (nextOption) => {
    const isActive = get().speedOption === nextOption;
    logger.debug("[onSpeedOptionChange] ", get().speedOption, isActive ? "<deactive>" : nextOption);

    const { start, end } = get().speedDates[nextOption];
    if (!start || !end) return;

    const { start: fetchedStart, end: fetchedEnd } = useNetworkStore.getState().fetchedRange;
    if (!fetchedStart || !fetchedEnd) return;
    const [min, max] = isActive ? [fetchedStart, fetchedEnd] : [start, end];
    set({ speedOption: isActive ? "" : nextOption });
    get().setNavigatorExtremes(min.toMillis(), max.toMillis());
  },

  onIntervalChange: (nextInterval) => {
    logger.debug("onIntervalChange(%s) ", nextInterval);
    set({
      interval: nextInterval,
      averaged: nextInterval === INTERVAL_DAILY,
    });
  },

  onNavigatorExtremesUpdated: (event) => {
    const chart = useNetworkStore.getState().chartRefs?.primary?.current?.chart?.xAxis?.[0];
    if (!chart || typeof chart.setExtremes !== "function") return;
    logger.debug("onNavigatorExtremesUpdated()");

    let start = DateTime.fromMillis(event.min);
    let end = DateTime.fromMillis(event.max);

    const { fetchedRange, setFilterRange } = useNetworkStore.getState();
    if (fetchedRange.start && start < fetchedRange.start) {
      start = fetchedRange.start;
    }

    if (!get().averaged) {
      start = start.set({ minute: 0, second: 0, millisecond: 0 });
      end = end.set({ minute: 0, second: 0, millisecond: 0 });
    } else {
      end = end.set({ hour: 23 });
    }

    logger.debug("setFilterRange %j", { start, end });
    setFilterRange({ start, end });
  },

  setNavigatorExtremes: (startTs, endTs) => {
    const chartRefs = useNetworkStore.getState().chartRefs;
    if (chartRefs?.navigator?.current) {
      const navigatorChart = chartRefs.navigator.current.chart?.xAxis[0];
      if (navigatorChart) {
        navigatorChart.setExtremes(startTs, endTs);
      }
    }
  },

  fetchData: async () => {
    const { selectedSubstationId, selectedNetworkId, lpMonth, coordinates, fetchedRange } =
      useNetworkStore.getState();

    if (!fetchedRange.start || !fetchedRange.end) {
      logger.error("fetchData() - no fetchedRange");
      throw new Error("No fetchedRange set. Cannot fetch data!");
    }

    if (
      !selectedSubstationId ||
      !selectedNetworkId ||
      !lpMonth ||
      get().fetching ||
      get().seriesData ||
      get().error
    )
      return;
    set({ fetching: true });

    let blockData;
    try {
      blockData = await queryClient.ensureQueryData(
        getInfoBlockQueryOptions({
          resource_type: SUBSTATION,
          network_id: selectedNetworkId,
          resource_id: selectedSubstationId,
          block_names: [
            SBT.pricing.to_block_name(),
            SBT.core.to_block_name({ year: lpMonth.year, month: lpMonth.month }),
            SBT.location.to_block_name(),
          ],
        })
      );
    } catch (err) {
      get().setError((err as Error).message);
      logger.error(err);
    }

    const dataReader = blkReader(blockData, [
      ["name", [SBT.pricing.to_block_name(), "flow_limit"], "flow_limit"],
      ["name", [SBT.location.to_block_name(), SBT.location.col.weather_coordinates], "coords"],
    ]);

    const dataReaderCoords = dataReader(selectedSubstationId)?.coords;
    const weatherCoords = dataReaderCoords || coordinates;

    try {
      logger.debug("fetching data...");
      const [weather, meter] = await Promise.all([
        await queryClient.ensureQueryData(
          getWeatherData({
            resource_type: SUBSTATION,
            resource_id: selectedSubstationId,
            network_id: selectedNetworkId,
            coordinates: weatherCoords,
            date_min: fetchedRange.start.startOf("day"),
            date_max: fetchedRange.end.plus({ day: 1 }).endOf("day"),
            metrics: "t",
          })
        ),
        await queryClient.ensureQueryData(
          getMeterData({
            resource_uids: [selectedSubstationId],
            network_uid: selectedNetworkId,
            ts_start: fetchedRange.start.startOf("day"),
            ts_end: fetchedRange.end.endOf("day").plus({ hour: 1 }),
            stage: "clean",
            meter: "primary",
            components: ["heat_energy", "volume", "supplytemp", "returntemp"],
          })
        ),
      ]);

      set({
        fetching: false,
        data: { weather, meter },
        hasPartialData: !!weather || !!meter,
      });
      get().prepareSeriesData({ weather, meter });
    } catch (err) {
      logger.error("Network Error! Cannot fetch details: ", (err as Error).message);
      get().setError((err as Error).message);
      set({ data: {}, hasPartialData: false, fetching: false });
    }
  },

  prepareSeriesData(data) {
    if (!data) return;

    logger.trace("prepareSeriesData");
    const { fetchedRange } = useNetworkStore.getState();
    const start = fetchedRange?.start?.toMillis();
    const end = fetchedRange?.end?.plus({ hour: 1 })?.toMillis();
    const delta = HOUR_MILIS;
    const dataFns: any[] = [];
    [...columns, { key: "outdoor" }, { key: "ts" }].forEach((col) => {
      dataFns.push([
        col.key,
        (row: Record<string, number> = {}, rarr: (number | null)[] = []) => {
          if (isValue(row[col.key])) {
            rarr.push(row[col.key]);
          } else {
            rarr.push(null);
          }
        },
      ]);
    });

    const filters: [string, unknown, string][] = [
      ["name", ["meter", "ts"], "ts"],
      ["name", ["meter", "heat_energy"], "heat"],
      ["name", ["meter", "volume"], "vol"],
      ["name", ["meter", "supplytemp"], "st"],
      ["name", ["meter", "returntemp"], "rt"],
      ["derive", (r: { st: number; rt: number }) => r.st - r.rt, "dt"],
      ["name", ["weather", "t"], "outdoor"],
    ];

    const reader = blkReader(data, filters);

    set({
      seriesData: deriveTimeSeries({
        reader,
        start,
        end,
        delta,
        fns: dataFns,
      }).filter((row) => row.ts),
    });
  },
});

export default create<MaintenanceStore>()(
  zustandMiddlware(maintenanceStore, {
    name: "maintenanceStore",
  })
);
