import { Box, Button, Typography } from "@mui/material";
import { faPersonWalkingArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFileRoute, Link } from "@tanstack/react-router";

import { logger } from "@core/logger";

export const Route = createFileRoute("/")({
  component: Home,
});

function Home() {
  logger.debug("RENDER <Home>");
  return (
    <Box
      height="100%"
      width="100%"
      display=" flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        color: "white",
      }}
    >
      <Box
        height="100%"
        width="100%"
        display=" flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        zIndex={-1}
        sx={{
          position: "absolute",
          backgroundImage: `url(${"/backdrop.webp"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: "brightness(0.4)",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "300px",
          alignItems: "center",
          justifyContent: "space-around",
          padding: 1,
          background: ({ palette }) => palette.primary.main,
        }}
      >
        <img src="/hafslund.webp" alt="Hafslund" />
        <Typography variant="h2">Welcome to the PropAI</Typography>
      </Box>
      <Box mt={10}>
        <Button
          component={Link}
          to="/app"
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faPersonWalkingArrowRight} />}
          color="secondary"
          size="large"
          sx={{ fontSize: "2rem" }}
        >
          GO TO APP
        </Button>
      </Box>
    </Box>
  );
}
