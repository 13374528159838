import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import { GraphContainer } from "@shared";

export function PropertyDetailOptimization() {
  return (
    <Box width="100%">
      <Grid container columnSpacing={1} my={2}>
        <Grid item xs={12}>
          <GraphContainer title="Indoor temperature & effective setpoint" subTitles="" hasData>
            chart here
          </GraphContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
