import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import data from "./FloorTable.mock.json";

const FloorTable = () => {
  const RED_THRESHOLD = 24.5;
  const getBgColor = (value: null | number) => {
    if (value === null) return "default";
    if (value >= RED_THRESHOLD) return "red.light";
    if (value >= 23.0) return "yellow.light";
    return "default";
  };
  const getColor = (value: null | number) => {
    if (value === null) return "black";
    if (value >= RED_THRESHOLD) return "white";
    if (value >= 23.0) return "white";
    return "grey";
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>Floor</TableCell>
            {[...Array(5)].map((_, index) => (
              <TableCell key={index} align="center" colSpan={2}></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.floor}>
              <TableCell>{row.floor}</TableCell>
              {row.rooms.map((room) => (
                <React.Fragment key={room.id}>
                  <TableCell align="right">{room.id}</TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      bgcolor: getBgColor(room.value),
                      color: getColor(room.value),
                      p: 1,
                      fontSize: "12px",
                    }}
                  >
                    {room.value !== null ? room.value.toFixed(1).replace(".", ",") : "-"}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FloorTable;
