import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { formatNumberForLocale, formatNumberForUnitMainLabel } from "@core";
import { BaseCard } from "@shared";
import type { BaseCardProps } from "@shared/ui/analytics/cards/BaseCard/BaseCard";

type DeviationCardProps = {
  rows: { address: string; value: number; change: number }[];
} & BaseCardProps;

export default function DeviationCard({
  title,
  titleAddon,
  loading,
  rows = [],
}: DeviationCardProps) {
  return (
    <BaseCard {...{ title, titleAddon, loading }}>
      <Box
        width="100%"
        sx={{
          px: 3,
          py: 2,
          table: {
            borderColor: (theme) => theme.palette.border.light,
            borderWidth: "1px",
            borderStyle: "solid",
            width: "100%",
            tr: {
              "&:nth-of-type(even)": {
                backgroundColor: (theme) => theme.palette.background.default,
              },
              "&:last-child td, &:last-child th": {
                border: 0,
              },
              td: {
                "*": {
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  fontWeight: "normal",
                },
                "*:not(span)": {
                  color: (theme) => theme.palette.primary.light,
                },
                px: 1,
                span: {
                  display: "inline-block",
                },
              },
            },
          },
        }}
      >
        <Table size="small">
          <TableBody>
            {rows.map((row) => {
              const changeColor = row.change < 0 ? "red.main" : "green.main";
              const [changeFloat] = formatNumberForUnitMainLabel(row.change, "kW");
              return (
                <TableRow key={`deviation-row-${row.address}`}>
                  <TableCell>
                    <Typography variant="body2" textTransform="capitalize">
                      {row.address}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: "#747b80",
                    }}
                  >
                    {formatNumberForLocale(row.value)}{" "}
                    <Box component="span" color={changeColor}>
                      {`${row.change > 0 ? "+" : ""}${changeFloat}%`}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </BaseCard>
  );
}
