/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, hexToRgb } from "@mui/material";
import { orange } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import hcBoost from "highcharts/modules/boost";
import { DateTime } from "luxon";

hcBoost(Highcharts);

type ChartNavigatorProps = {
  data: { [key: string]: any } | undefined;
  range: {
    start: DateTime;
    end: DateTime;
  };
  chartRef: any;
  afterSetExtremes?: (extremes: { min: number; max: number }) => void;
};

export const ChartNavigator = ({
  data = [],
  range = { start: null, end: null },
  chartRef,
  afterSetExtremes,
}: ChartNavigatorProps) => {
  const theme = useTheme();

  if (!range || !data.length) return;

  const startDateTs = range.start.toMillis();
  const endDateTs = range.end.toMillis();

  const seriesData = data.map((row) => {
    return row?.heat ?? row.outdoor;
  });

  const series = [
    {
      type: "spline",
      name: "",
      pointStart: startDateTs,
      pointInterval: 3600 * 1000, // We just show hourly data
      data: seriesData,
      lineWidth: 0,
      marker: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: false,
        },
      },
      yAxis: 0,
    },
  ];

  return (
    <Box
      width="100%"
      sx={{
        borderTop: `${theme.palette.grey["50"]} 1px solid`,
        borderBottom: `${theme.palette.grey["50"]} 1px solid`,
      }}
    >
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="stockChart"
        options={{
          boost: {
            enabled: true,
            seriesThreshold: 1,
          },
          scrollbar: {
            barBorderRadius: 0,
            barBorderWidth: 1,
            barBackgroundColor: theme.palette.primary.light,
            barBorderColor: theme.palette.common.white,
            buttonsEnabled: true,
            margin: 0,
            rifleColor: theme.palette.primary.main,
            trackBackgroundColor: theme.palette.common.white,
            trackBorderRadius: 0,
          },
          chart: {
            id: "navigator",
            height: 25,
            spacing: 0,
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.text.primary,
          },
          navigator: {
            enabled: true,
            height: 15,
            outlineColor: theme.palette.text.primary,
            maskFill: hexToRgb(`${orange[500]}80`),
            xAxis: {
              labels: {
                style: {
                  color: theme.palette.common.white,
                },
              },
            },
          },
          navigation: {
            buttonOptions: {
              enabled: false,
            },
          },
          title: {
            text: "",
          },
          rangeSelector: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          yAxis: {
            title: null,
            gridLineWidth: 0,
            visible: false,
            labels: {
              enabled: false,
            },
          },
          xAxis: {
            type: "datetime",
            labels: {
              enabled: false,
            },
            tickLength: 0,
            lineWidth: 0,
            minRange: 1,
            min: startDateTs,
            max: endDateTs,
            events: {
              afterSetExtremes,
            },
          },
          series,
        }}
      />
    </Box>
  );
};
