/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, Drawer } from "@mui/material";
import { styled, useTheme, type Theme } from "@mui/material/styles";
import { DateTime, type DateTime as DateTimeT } from "luxon";

import { DATE_FORMAT, DATETIME_FORMAT_DEFAULT, INTERVAL_HOURLY, INTERVAL_OPTIONS } from "@config";
import { logger as baseLogger } from "@core/logger";
import { useDebouncedValue } from "@hooks";

import { ChartNavigator } from "./ChartNavigator";
import { ButtonDropdown } from "./ui/inputs/ButtonDropdown";
import UtfDateRangePicker from "./ui/inputs/UtfDateRangePicker/UtfDateRangePicker";
import withErrorBoundary from "./ui/withErrorBoundary";

const logger = baseLogger.getSubLogger({ name: "<FilterBar/>" });
const DRAWER_WIDTH = "100%";
const ROW_HEIGHT = 56;

const Row = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  width: "100%",
  minHeight: ROW_HEIGHT,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[1],
  gap: theme.spacing(2),
}));

const ToggleButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.8),
  boxShadow: theme.shadows[1],
  cursor: "pointer",
  svg: {
    color: "currentcolor",
  },
}));

const Section = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "auto",
  height: "100%",
  alignItems: "center",
  color: theme.palette.grey["50"],
  gap: theme.spacing(1),
}));

const DrawerBody = styled("div")(({ theme }) => ({
  color: "white",
  display: "flex",
  overflow: "hidden",
  backgroundColor: theme.palette.primary.light,
}));

function VerticalDivider() {
  return (
    <Divider orientation="vertical" variant="inset" flexItem sx={{ width: "2px", margin: 0 }} />
  );
}

function openedMixin(theme: Theme) {
  return {
    height: `calc(${ROW_HEIGHT} * 2 + 1px)`,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  };
}

function closedMixin(theme: Theme) {
  return {
    height: `calc(${ROW_HEIGHT} + 1px)`,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  };
}

function FilterDrawer({ children, ...props }) {
  const theme = useTheme();
  const paperDefaults = { width: DRAWER_WIDTH, boxSizing: "border-box" };
  return (
    <Drawer
      variant="permanent"
      anchor="bottom"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        position: "fixed", // do not retain a space in the dom
        width: DRAWER_WIDTH,
        flexShrink: 0,
        ...(props.open && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": {
            ...paperDefaults,
            ...openedMixin(theme),
            "& > div": {
              ...openedMixin(theme),
            },
          },
        }),
        ...(!props.open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": {
            ...paperDefaults,
            ...closedMixin(theme),
            "& > div": {
              ...closedMixin(theme),
            },
          },
        }),
      }}
      {...props}
    >
      {children}
    </Drawer>
  );
}

type FilterBarProps = {
  data: { [key: string]: number }[] | undefined;
  disabled: boolean;
  range: { start: DateTimeT; end: DateTimeT };
  onChange: (start: DateTimeT, end: DateTimeT) => void;
  onStartChange: (start: DateTimeT) => void;
  onEndChange: (end: DateTimeT) => void;
  registerChart: (id: string, ref: any) => void;
  onIntervalChange?: (nextInterval: string) => void;
  afterSetExtremes: (extremes: { min: number; max: number }) => void;
};

const FilterBarComponent: FC<FilterBarProps> = ({
  data,
  range = { start: null, end: null },
  disabled,
  onChange,
  onIntervalChange,
  afterSetExtremes,
  registerChart,
}) => {
  const navigatorChartRef = useRef();
  const { t } = useTranslation(["extendView"]);
  const [open, setOpen] = useState(true);
  const [interval, setInterval] = useState(INTERVAL_HOURLY);
  const [extremes, setExtremes] = useState(() => [range.start.toMillis(), range.end.toMillis()]);

  const onExpandToggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const [afterMin, afterMax] = useDebouncedValue(extremes, 300);
  useEffect(() => {
    if (afterSetExtremes) afterSetExtremes({ min: afterMin, max: afterMax });
  }, [afterMax, afterMin, afterSetExtremes]);

  const handleAfterSetExtremes = ({ min, max }: { min: number; max: number }) => {
    setExtremes([min, max]);
    if (onChange) {
      const start = DateTime.fromMillis(min);
      const end = DateTime.fromMillis(max);
      onChange(start, end);
    }
  };

  const handleInputChange = (start: DateTimeT | null, end: DateTimeT | null) => {
    logger.trace("handleInputChange %j", { start, end });
    if (!end) return;
    onChange(start, end);
  };

  const handleIntervalChange = (nextInterval: string) => {
    if (!onIntervalChange) return;
    logger.trace("handleIntervalChange", nextInterval);
    setInterval(nextInterval);
    onIntervalChange(nextInterval);
  };

  useEffect(() => {
    if (!navigatorChartRef.current || !data) return;
    registerChart("navigator", navigatorChartRef);
  }, [data, navigatorChartRef, registerChart]);

  logger.trace("RENDER");

  return (
    <FilterDrawer open={open} data-testid="floating-filterbar">
      <DrawerBody>
        <Box flex={1}>
          {/* First, always-visible row */}
          <Row>
            <Section sx={{ minWidth: "100%" }}>
              <ChartNavigator
                data={data}
                range={range}
                chartRef={navigatorChartRef}
                afterSetExtremes={handleAfterSetExtremes}
              />
            </Section>
          </Row>

          {/* Second, hideable row */}
          {open && (
            <Row data-testid="floating-filterbar-hideable">
              <Box flex={1} />
              <VerticalDivider />
              {/* Intervals */}
              <Section>
                <Box>{t("extendView:text_resolution")} :</Box>
                <ButtonDropdown
                  options={INTERVAL_OPTIONS}
                  selectedValue={interval}
                  onChange={(nextInterval) => handleIntervalChange(nextInterval)}
                  disabled={disabled}
                  variant="outlined"
                  placement="top"
                  data-testid="interval-btn"
                  translationNs="_common"
                />
              </Section>
              <VerticalDivider />
              <Section>
                <Box
                  sx={{
                    ".react-datepicker__input-container > div": {
                      width: "320px",
                    },
                  }}
                >
                  <UtfDateRangePicker
                    startDate={DateTime.fromMillis(afterMin)}
                    endDate={DateTime.fromMillis(afterMax)}
                    placeholder="Shown Date Range"
                    minDate={range.start}
                    maxDate={range.end}
                    dateFormat={
                      interval === INTERVAL_HOURLY ? DATETIME_FORMAT_DEFAULT : DATE_FORMAT
                    }
                    isClearable={false}
                    onChange={handleInputChange}
                    onDark
                  />
                </Box>
              </Section>
            </Row>
          )}
        </Box>
        {/* Expansion Toggler */}
        <ToggleButton onClick={onExpandToggle}>
          <ExpandMoreIcon sx={{ transform: open ? undefined : "rotate(180deg)" }} />
        </ToggleButton>
      </DrawerBody>
    </FilterDrawer>
  );
};
FilterBarComponent.displayName = "Floating.FilterBar";

export const FilterBar = withErrorBoundary<FilterBarProps>(FilterBarComponent);
